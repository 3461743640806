import React, { useEffect, useRef, useState } from "react";
import Header from "../../components/header/header";
import Stamp from "../../components/stamp/stamp";
import Quiz from "../quiz/quiz";
import Win from "../win/win";
import axios from "axios";

function Home({setLaunchQr, openQuiz, setOpenQuiz, quizIndex, setScannerOrQr, authToken, setAuthToken}) {
    const stampInfo = [
        {
            name: "Booth 1",
            description: "Visit booth to participate",
            quiz_question: 'What does the acronym "HTML" stand for in web development?',
            quiz_answers: ["Hyper Text Markup Language", "High Text Multi Language", "Hyper Transfer Multi Layer", "Hyper Text Machine Language"],
            quiz_correct_answer: 0
        },
        {
            name: "Booth 2",
            description: "Visit booth to participate",
            quiz_question: 'In software development, what does the acronym "API" stand for',
            quiz_answers: ["Automated Programming Integration", "Advanced Process Integration", "Application Program Instruction", "Application Programming Interface"],
            quiz_correct_answer: 3
        },
        {
            name: "Booth 3",
            description: "Visit booth to participate",
            quiz_question: "What is the chemical symbol for the element Gold?",
            quiz_answers: ["Ag", "Au", "Gd", "Ld"],
            quiz_correct_answer: 1
        },
        {
            name: "Booth 4",
            description: "Visit booth to participate",
            quiz_question: "Select the correct answer",
            quiz_answers: ["answer", "ANSWER", "correct answer", "the correct answer"],
            quiz_correct_answer: 3
        },
        {
            name: "Booth 5",
            description: "Visit booth to participate",
            quiz_question: null,
            quiz_answers: null,
            quiz_correct_answer: null
        }
    ]
    const n = stampInfo.length;
    const [giftRedeemStatus, setGiftRedeemStatus] = useState('unredeemable');
    const [stampStatus, setStampStatus] = useState(Array(n).fill(false));
    const [winPage, setWinPage] = useState(false);
    
    const checkStamps = async () => {
        try {
            const response = await axios.post(
              "https://api.virtualtechfrontier.com/wp-json/api/ge-check-stamp",
              {
                user_id: authToken.user_id,
              }
            ); 
            const data = response.data[0];
            let newStampStatus = [...stampStatus];
            // update stamp status based on server response
            for (let i=1; i <= n; i++) {
                if (data[`stamp_${i}`] == '1'){
                    newStampStatus[i-1] = true;
                }
            }
            await setStampStatus(newStampStatus);
            console.log("Stamp status", stampStatus);
            console.log("redeem status", giftRedeemStatus);
            console.log("data", data);
            if (data.redeem_status == '1') {
                setGiftRedeemStatus('redeemed');
            } else if (newStampStatus.every(elem => elem == true) && data.redeem_status == '0') {
                setGiftRedeemStatus('redeemable');
            } else {
                setGiftRedeemStatus('unredeemable');
            }
          } catch (error) {
            console.log("Error checking stamps", error);
          }
    }; 

    useEffect(() => {
        checkStamps();
    },[]);

  return (
    <div className="home">
      <Header userName={authToken.name} setAuthToken={setAuthToken} />
      <div id='content'>
        {openQuiz ? <Quiz setOpenQuiz={setOpenQuiz} quizIndex={quizIndex} question={stampInfo[quizIndex].quiz_question} answers={stampInfo[quizIndex].quiz_answers} correctAnswer={stampInfo[quizIndex].quiz_correct_answer} stampStatus={stampStatus} setStampStatus={setStampStatus} setWinPage={setWinPage} userId={authToken.user_id} checkStamps={checkStamps} /> : winPage ? <Win setWinPage={setWinPage} /> : <>
        <div id='upper-content'>
            <div id='instructions'>
                <h3 id='instruction-title'>Visit & Redeem</h3>
                <p id='instruction-text'>Visit all {n} booths to participate and redeem a (Prize Name)</p>
                <p id="instruction-counter">{stampStatus.filter(e => e == true).length}/{n} Complete</p>
            </div>
        </div>
        <div id='lower-content'>
            {stampInfo.map((info, index) => 
                <Stamp key={index} info={info} index={index} setLaunchQr={setLaunchQr} stampStatus={stampStatus[index]} />
            )}
        </div>
        <button id='redeem-button' disabled={giftRedeemStatus == 'unredeemable' || giftRedeemStatus == 'redeemed'} className={giftRedeemStatus == 'unredeemable' ? 'disable-redeem' : giftRedeemStatus == 'redeemable' ? 'enable-redeem' : 'redeemed'} onClick={() => {setLaunchQr(true); setScannerOrQr(false)}}>{giftRedeemStatus == 'redeemed' ? "Redeemed" : "Redeem Gift"}</button>
        </>}
        
      </div>
      
    </div>
  );
}

export default Home;
