import React, { useRef, useState } from "react";
import uncompletedStamp from "../../imgs/uncompleted-stamp.svg";
import completedStamp from "../../imgs/completed-stamp.svg";

function Stamp({info, index, setLaunchQr, stampStatus}) {
    const handleButtonClick = () => {
        if (!stampStatus) {
            setLaunchQr(true);
        }
    };
    return (
        <div id='stamp'>
            <div id='stamp-top' >Activity {index + 1} • &nbsp;<p id='completion-status' className={stampStatus ? 'completed-text-color' : 'uncompleted-text-color'}>{stampStatus ? 'Complete' : 'Not complete'}</p></div>
            <div id='stamp-bottom'>
                <h4>{info.name}</h4>
                <p>{info.description}</p>
            </div>
        <div id='stamp-btn' onClick={handleButtonClick} className={stampStatus ? 'completed-btn-color' : 'uncompleted-btn-color'}>
            <div id='stamp-btn-inner'>
                {stampStatus ? <><img src={completedStamp} alt='Completed Stamp' /><p id='visited-text'>VISITED</p></> : <><img src={uncompletedStamp} alt='Uncompleted Stamp' /><p id='scanQr-text'>Scan QR</p></>}
                
                </div>
        </div>
        </div>
    )
};
export default Stamp;