import React from "react";
import winImg from "../../imgs/win.svg";

const Win = ({setWinPage}) => {
    const handleDoneClick = () => {
        setWinPage(false);
    };
    return (
        <div id='win'>
            <img src={winImg} alt='Win Badge' />
            <h3>You Got It!</h3>
            <p>Good job! You got all questions correct. Proceed to collect your stamp at the booth.</p>
            <button onClick={()=>handleDoneClick()}>Done</button>
        </div>
    )
};
export default Win;