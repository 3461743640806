import React, {useState} from "react";
import QrScanner from "react-qr-scanner";
import axios from "axios";


const Admin = () => {
    const [isScannerOpen, setIsScannerOpen] = useState(false);
    const scan = async (data) => {
        if (data){
            setIsScannerOpen(false);
            console.log(data);
        try {
            const response = await axios.post(
              "https://api.virtualtechfrontier.com/wp-json/api/ge-redeem",
              {
                user_id: data.text,
              }
            );
           console.log(response);
          } catch (error) {
            console.log("Error Redeeming", error);
          }
        }
        
    };
    return (
        <div id='admin' style={{height: '100dvh', width:'100dvw', backgroundColor:'#005E60', display: 'flex', justifyContent:'center', alignItems:'center', flexDirection:'column',position:'fixed', zIndex:'9999'}}>
            <h1 style={{color: 'white', position: 'fixed', top: '5%'}}>Admin Page</h1>
            {isScannerOpen  && <QrScanner
                    delay={300}
                    style={{height: '70%', width:'90%'}}
                    onError={(err) => console.error(err)}
                    constraints={{ video: { facingMode: "environment" } }}
                    onScan={scan}
                />}
                
            <button style={{width: '80%', height: '7dvh', border: 'none', borderRadius:'1dvh', boxShadow: '0 8px 15px rgba(0, 0, 0, 0.2)'}} onClick={() => setIsScannerOpen(!isScannerOpen)}>Trigger Scanner</button>
        </div>
    )
};
export default Admin;