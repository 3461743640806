import React, { useRef, useState } from "react";
import userIcon from "../../imgs/user-icon.svg"

function Header({userName, setAuthToken}) {
  const deleteAuthToken = (name) => {
    document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
    setAuthToken(null);
  };

  return (
    <div className="header">
        <div id='header-left'><img src={userIcon} alt='user icon' />{userName}</div>
        <div id='header-right' onClick={() => deleteAuthToken("authToken")}>Logout</div>
    </div>
  );
}

export default Header;
