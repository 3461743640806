import './App.css';
import { useEffect, useState } from 'react';
import Registration from './pages/registration/registration';
import Home from './pages/home/home';
import Footer from './components/footer/footer';
import QR from './components/qr/qr';
import Admin from './pages/admin/admin';

function App() {
  const [authToken, setAuthToken] = useState(null);
  const [launchQr, setLaunchQr] = useState(false);
  const [scannerOrQr, setScannerOrQr] = useState(true); // true for scanner, false for qr
  const [openQuiz, setOpenQuiz] = useState(false);
  const [quizIndex, setQuizIndex] = useState(null);

  const getCookie = (name) => {
    const value = document.cookie;
    const parts = value.split(`${name}=`);
    if (parts.length === 2) {
      console.log(parts);
      return JSON.parse(parts[1]);
    } else {
      return false;
    }
  };

  useEffect(() => {
    let token = getCookie("authToken");
    if (token) {
      setAuthToken(token);
    }
  }, []);
  const [isAdmin, setIsAdmin] = useState(false);

  return (
    <div className="App">
      {authToken != null ? 
      (<Home setLaunchQr={setLaunchQr} openQuiz={openQuiz} setOpenQuiz={setOpenQuiz} quizIndex={quizIndex} setScannerOrQr={setScannerOrQr} authToken={authToken} setAuthToken={setAuthToken}/>) 
      : 
      (<Registration setAuthToken={setAuthToken} getCookie={getCookie} setIsAdmin={setIsAdmin}/>)
      }
      {launchQr ? <QR userId={authToken.user_id} setLaunchQr={setLaunchQr} setOpenQuiz={setOpenQuiz} setQuizIndex={setQuizIndex} scannerOrQr={scannerOrQr}/> : null}
      <Footer /> 
      {isAdmin ? <Admin /> : null}
    </div>
  );
}

export default App;